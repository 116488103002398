<template>
    <img :src="logo" alt="" class="logo">
</template>

<script>
import Logo from "@/assets/logo-armed.png"

export default {
    name: 'logo-url',
    data(){
        return{
            logo: Logo
        }
    }
}
</script>

<style>

</style>