<template>
    <div>
        <div class="d-flex container-custom">
            <div class="data-left">
                <div class="data-left-content">
                    <router-link to="/" class="d-block text-center font-bold custom-link logo-container auth-logo-container" style="font-size:24px;">
                        <logo></logo>
                        <!-- <img :src="logo" alt="" class="logo"> -->
                        <!-- <img :src="require(`../../assets/logo-armed.png`)" alt="" class="logo"> -->
                    </router-link>
                    <div class="text-center">
                        <h3 class="mt-3"><b>Selamat datang <br> di Armed.</b></h3>
                        <p>Silahkan masukkan email/username dan kata sandi Anda</p>
                    </div>
                    <form @submit.prevent="login" class="w-100">
                        <div class="form-group mt-4">
                            <label for="email">Email atau Username</label>
                            <input type="text" class="form-control radius" id="email" placeholder="Email/Username" v-model="email" 
                            :class="[v$.email.$error ? 'is-invalid': '']"
                            >
                            <span  v-if="v$.email.$error" class="text-danger font-small pesan-error" id="error-email"> {{ v$.email.$errors[0].$message }}</span>

                        </div>
                        <div class="form-group mt-4">
                            <label for="password">Kata Sandi</label>
                            <div class="position-relative password-input">
                                <input type="password" class="form-control radius" id="password" placeholder="Kata sandi" autocomplete="false" v-model="password" 
                                :class="[v$.password.$error ? 'is-invalid': '']"
                                >
                                <div v-if="!v$.password.$error" class="icon-container" @click="togglePass('password')">
                                    <i class='bx bx-hide'></i>
                                </div>
                            </div>

                            <span v-if="v$.password.$error" class="text-danger font-small pesan-error" id="error-password">{{ v$.password.$errors[0].$message }}</span>
                        </div>

                        <div v-if="errorMessage != ''" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                            <strong>Peringatan ! </strong> 
                            <span> {{errorMessage}} </span>
                        </div>
                        <div class="form-group mt-2">
                            <router-link to="/lupa-password" class="linkforgot" style="float:right;">Lupa Kata Sandi?</router-link>
                        </div>
                        <div class="form-group mt-5">
                            <button type="submit" class="btn btn-red w-100 btn-radius">Masuk</button>
                            <div class="mt-2 hr-login">
                                <span>
                                    Atau 
                                </span>
                            </div>
                            <router-link to="/register" class="btn btn-outline-red w-100 mt-3 btn-radius" >Daftar</router-link>
                        </div>
                    </form>
                </div>
            </div>
            <div class="data-right">
                <img :src="baseUrl+'storage/'+loginImage" alt="">
            </div>
        </div>


        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import { required, minLength, helpers} from '@vuelidate/validators'
import Logo from "@/components/Logo.vue";

export default {
    name: 'login-component',
    props:['banners'],
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
        Logo
    },
    data(){
        return{
            email: "",
            password: "",
            errorMessage: '',
            loadingSubmit: false,
            loginImage: {},
            baseUrl: process.env.VUE_APP_NAME_AXIOS
        }
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage("Email / Username tidak boleh kosong", required),
                // email: helpers.withMessage("Format email salah", email)
            },
            password: {
                required: helpers.withMessage("Kata sandi tidak boleh kosong", required), 
                min: helpers.withMessage(
                    ({
                        $params
                    }) => `Kata sandi minimal memiliki ${$params.min} karakter `,
                    minLength(8)
                )
            },
        }
    },
    mounted(){
        this.setImage()
    },
    methods: {
        togglePass(id){
            if(document.querySelector('#'+id).type == 'password'){
                document.querySelector('#'+id).type = 'text'
                document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-hide')
                document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-show')
            }else {
                document.querySelector('#'+id).type = 'password'
                document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-show')
                document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-hide')
            }
        },
        setImage(){
            if(this.banners != null){
                for(let i = 0; i < Object.keys(this.banners).length; i++){
                    if(this.banners[i].position === 'Login Page'){
                        this.loginImage = this.banners[i].image
                        // console.log(this.baseUrl+this.loginImage);
                    }
                }
                this.loadingMainBanner = false
            }
        },
        login() {

            this.v$.$validate()
            let data = new FormData();
            data.append('email', this.email)
            data.append('password', this.password)
            this.errorMessage = ''

            if ( !this.v$.$error) { 
                this.loadingSubmit =  true

                axios.post('customer/login', data)
                .then(response => {
                    this.$cookies.set('token', response.data.data.token);
                    window.location.replace('/')
                    this.loadingSubmit =  false

                })
                .catch(error => {
                    
                    let val = Object.values(error.response.data)
                    let key = Object.keys(error.response.data)
                    for(let j = 0; j < key.length; j++){
                        if(key[j] == 'message'){
                            if(val[j] != ''){
                                this.errorMessage = val[j] 
                            }
                        }
                    }
                    this.loadingSubmit =  false
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });

                }); 
            }
        },
    },
     watch:{
        banners:{
            deep: true,
            handler: function(){
                this.setImage()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }
}
</script>


<style scoped>
.container-custom{
    display: flex;
    flex-direction: column;
}
.password-input .icon-container{
    position:  absolute;
    top:0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items:center;
    padding: 1rem;
}

.password-input .icon-container:hover{
    cursor:pointer;
}

.password-input input[type="password"]{
    padding-right: 2.5rem;
}

.data-left{
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:70px 20px;
}
.data-left,
.data-right{
    width: 100%;
}
.data-left-content{
    width: 85%;
}
.data-left small{
    color: var(--color-light);
}
.data-right{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    z-index: 100;
    height: auto;
    /* background: url('../../assets/national-cancer-institute-GcrSgHDrniY-unsplash.webp'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: none;
}
.data-right img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.hr-login{
    width: 100%; 
    height: 13px; 
    border-bottom: 1px solid rgba(0, 0, 0, 0.5); 
    text-align: center
}
.hr-login span{
    padding: 0 10px;
    color: var(--color-light);
    background-color: var(--secondary-color);
}
.radius{
    border-radius: 25px;
    padding: 0.7rem 1.5rem
}
.btn-radius{
    border-radius: 25px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.linkforgot{
    float: right;
    color: var(--color-light);
    font-size: 0.8rem;
    text-decoration: none;
    padding-right: 15px;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .container-custom{
        flex-direction: row;
    }
    .data-left,
    .data-right{
        width: 50%;
    }

    .data-right{
        display: block;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>